<template>
  <div class="relative clearfix">
    <div class="float-left mt-4">
      <div @click="filterDropdownFlag = false" v-if="filterDropdownFlag === true" class="fixed inset-0 bg-transparent filter-z-index"></div>
      <div class="relative md:w-48 w-full" :class="filterDropdownFlag ? 'filter-z-index' : ''">
        <div @click="filterDropdownFlag = !filterDropdownFlag" class="flex gap-4 cursor-pointer bg-white items-center border border-solid border-grey-light p-2 rounded-lg whitespace-nowrap overflow-hidden">
          <span class="material-icons-outlined text-lg">equalizer</span>
          <p class="truncate w-full capitalize font-medium text-sm">{{filters.length === 0 ? $t('Status') : filters.map(item => $t(item.replaceAll('_', ' '))).join(', ')}}</p>
          <div class="text-right flex items-center">
            <feather-icon class="w-4 h-4" :icon="filterDropdownFlag ? 'ChevronUpIcon' : 'ChevronDownIcon'"></feather-icon>
          </div>
        </div>
        <div v-if="filterDropdownFlag" class="absolute w-full left-0 bg-white shadow-md p-2 rounded-lg " style="top: 40px;">
          <div @click="changeFilter(item.value)" v-for="item in statusFilters" :key="item.index">
            <vs-checkbox class="mb-2 " :checked="filters.length === 0 && item.value === 'all' ? true : filters.includes(item.value)" :vs-value="item.value">
              <span class="capitalize">{{ $t(item.name) }}</span>
            </vs-checkbox>
          </div>
        </div>
      </div>
    </div>

    <ShipBluTable
      :sst="true"
      multiple
      search
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      :data="tableData"
      :tableLoader="tableLoader"
    >
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between"></div>
      <template slot="thead">
        <shipblu-th>{{$t('Name')}}</shipblu-th>
        <shipblu-th>{{$t('HR ID')}}</shipblu-th>
        <shipblu-th>{{$t('Start')}}</shipblu-th>
        <shipblu-th>{{$t('Completion')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Score')}}</shipblu-th>
        <shipblu-th>{{$t('Hub')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" class="text-left">
          <shipblu-td :data="data[indextr].name">
            {{ data[indextr].name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].hr_id">
            {{ data[indextr].merchant_hr_id !== null ? data[indextr].merchant_hr_id : data[indextr].customer_hr_id !== null ? data[indextr].customer_hr_id : 'N/A' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].assigned_date">
            {{ data[indextr].assigned_date }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].completed_date">
            {{ data[indextr].completed_date }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].status">
            <span class="capitalize"> {{ $t(data[indextr].status.replace(/_/g, ' ')) }} </span>
          </shipblu-td>

          <shipblu-td :data="data[indextr].final_score">
            {{ data[indextr].final_score }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].hub">
            {{ data[indextr].merchant_hub !== null ? data[indextr].merchant_hub : data[indextr].customer_hub !== null ? data[indextr].customer_hub : 'N/A' }}
          </shipblu-td>
          
        </shipblu-tr>
      </template>
    </ShipBluTable>

    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
  </div>
</template>

<script>
import TableFilter from '../../merchant/components/TableFilter.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import common  from '@/assets/utils/common'
import { sendRequest } from '../../../http/axios/requestHelper'
import ShipBluTable from '../../../layouts/components/ShipBluTable.vue'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      tableData: [],
      filterDropdownFlag: false,
      statusFilters: [
        {
          name: 'ALL',
          value: 'all'
        },
        {
          name: 'ASSIGNED',
          value: 'assigned'
        },
        {
          name: 'IN PROGRESS',
          value: 'in_progress'
        },
        {
          name: 'COMPLETED',
          value: 'completed'
        },
        {
          name: 'OVERDUE',
          value: 'overdue'
        },
        {
          name: 'WAIVED',
          value: 'waived'
        }
      ],
      filters: [],
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      searchInProgress: false,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      tableLoader: false,
      searchedValue: ' ',
      searchVal: '',
      selected: [],
      offset: 0,
      totalRows: 0
    }
  },
  methods: {
    changeFilter (type) {
      this.filterValue = type
      const filtersTemp = this.filters
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      if (this.filterValue === 'all') {
        this.filters = []
      }
      this.$router.push({
        query: {
          tab: this.$route.query.tab,
          filter: this.filters,
          programId: this.$route.query.programId,
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadInsights()
    },
    handleSearch (search) {
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadInsightsSearch)
    },
    loadInsightsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadInsights()
    },
    loadInsights () {
      this.tableLoader = true
      this.searchInProgress = true
      sendRequest(false, false, this, `api/v1/training/program-exam/?status=${this.filters}&search=${this.searchVal}`, 'get', null, true,
        (response) => {
          this.tableData = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    }
  },
  watch: {
    '$route.query.filter' () {
      if (!this.$route.query.filter) {
        this.filters = []
        this.loadInsights()
      }
    },
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          tab: this.$route.query.tab,
          filter: this.filters,
          programId: this.$route.query.programId,
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadInsights()
    }
  },
  components: {
    TableFilter,
    ShipBluTable,
    ShipbluTd,
    ShipbluTr,
    ShipbluTh,
    ShipbluPagination
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
    this.loadInsights()
  }
}
</script>

<style lang="scss">
th {
  height: 50px ;
  vertical-align: center;
  font-size: 14px;
  font-weight: 400;
}
.filter-z-index {
  z-index: 9999;
  user-select: none;
}
table {
  min-width: 700px;
}
.con-pagination-table {
  display: none;
}
</style>