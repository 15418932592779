<template>
  <div class="mt-10">
    <p class="text-xl font-medium text-black sm:mb-6 mb-4">{{ $t('Create Program Chapters') }}</p>
    <div @dragover.prevent="onDragOver" @dragstart="onDragStart" @dragend="onDragEnd()" class="flex flex-col md:gap-6 gap-4">
      <div v-for="(item, index) in chaptersAdded" :key="index" draggable :data-index="index" @dragover.prevent @dragenter="onDragEnter(item, index)" :class="draggingIndex === index ? 'opacity-25' : ''">
        <div class="flex justify-between">
          <div class="xl:w-1/4 lg:w-2/5 sm:w-1/3 w-3/5">
            <p class="cursor-pointer font-medium sm:text-xl text-black" v-if="chaptersAdded[index].addChapterName === false" @click="allowEdit(chaptersAdded[index])">{{ item.name }}</p>
            <div v-else>
              <input placeholder="Chapter Title" @blur="createChapters(item, index)" @keyup.enter="createChapters(item, index)" autocomplete="nofill" name="chapter title" class="w-full input-order" v-model="item.name" v-validate="'required'"/>
              <span class="text-danger text-sm" v-show="errors.has('chapter title')">{{ errors.first('chapter title') }}</span>
            </div>
          </div>
          <div class="flex items-center gap-2 sm:mr-8">
            <span class="text-sm text-black">{{ chaptersAdded[index].visibility ? 'publish' : 'unpublish' }}</span>
            <vs-switch name="chapter publish" @input="createChapters(item, index)" v-model="chaptersAdded[index].visibility"/>
          </div>
        </div>
        <div class="flex justify-between gap-2">
          <div class="border border-solid rounded w-full border-grey-light sm:mt-4 mt-3 p-3">
            <div @click="$router.push(`/${$route.params.lang}/ops-admin/agents/training-program/module?chapterId=${item.id}&programId=${$route.query.programId}`).catch(() => {})" class="cursor-pointer border border-solid rounded w-full border-grey-light mb-2 sm:p-3 p-2" style="background-color: #F1F1F1 ;">
              <p class="text-center text-black sm:text-lg font-medium">{{ chaptersAdded[index].module_name ? chaptersAdded[index].module_name : 'Add Module' }}</p>
            </div>
            <div @click="$router.push(`/${$route.params.lang}/ops-admin/agents/training-program/quiz?chapterId=${item.id}&programId=${$route.query.programId}`).catch(() => {})" class="cursor-pointer border border-solid rounded w-full border-grey-light sm:p-3 p-2" style="background-color: #F1F1F1 ;">
              <p class="text-center text-black sm:text-lg font-medium">{{ 'Add Quiz' }}</p>
            </div>
          </div>
          <div class="mt-3 sm:block hidden">
            <span class="material-icons-outlined drag-indecator text-2xl indicator">drag_indicator</span>
          </div>
        </div>
      </div> 
    </div>
    <div @click="addMoreChapters" class="sm:mr-8 border border-solid rounded border-grey-light sm:mt-10 mt-6 p-6 flex items-center justify-center cursor-pointer">
      <span class="material-icons-outlined sm:text-3xl border border-solid border-grey rounded-full flex items-center justify-center text-grey sm:w-14 w-12 sm:h-14 h-12">add</span>
    </div>
  </div>
</template>

<script>
import { sendRequest } from '../../../http/axios/requestHelper'
import i18nData from '../../../i18n/i18nData'

export default {
  data () {
    return {
      chaptersAdded: [],
      draggingItem: {},
      draggingIndex: null // For tracking the item being dragged
    }
  },
  methods: {
    allowEdit (item) {
      item.addChapterName = true
      this.chaptersAdded = [...this.chaptersAdded]
    },
    loadChapters () {
      sendRequest(false, false, this, `api/v1/training/chapters/?training_program=${this.$route.query.programId}`, 'get', null, true,
        (response) => {
          this.chaptersAdded = response.data
          this.chaptersAdded.sort((firstQuestion, secondQuestion) => firstQuestion.sort_order - secondQuestion.sort_order)
          this.chaptersAdded.forEach(item => { item.addChapterName = false })
        }
      )
    },
    addMoreChapters () {
      if (this.$route.query.programId) {
        this.chaptersAdded.push({
          item: {
            chapterName: '',
            visibility: false,
            sort_order: null
          }
        })
      } else {
        this.$vs.notify({
          color:'danger',
          title:i18nData[this.$i18n.locale]['Error'],
          text:i18nData[this.$i18n.locale]['You need to fill program details first'],
          position: 'top-center'
        })
      }
    },
    // When the drag starts, store the index of the dragged item
    onDragStart (event) {
      this.draggingIndex = Number(event.target.dataset.index)
    },

    // When the drag ends, restore the item display and update the questions
    onDragEnd () {
      this.updateOnDrag()
      this.draggingIndex = null
    },

    // Handle the drag over event to determine the new position of the dragged item
    onDragOver (event) {
      event.preventDefault()
    },

    // This method is called when a dragged item enters another item's area
    onDragEnter (item, index) {
      this.draggingItem = item
      if (this.draggingIndex !== index) {
        const draggedItem = this.chaptersAdded[this.draggingIndex]
        this.chaptersAdded.splice(this.draggingIndex, 1) // Remove the item from the array
        this.chaptersAdded.splice(index, 0, draggedItem) // Insert it at the new position
        this.draggingIndex = index // Update the current dragging position
      }
    },
    createChapters (chapter, index) {
      this.$validator.validateAll().then(result => {
        if (result) {
          const chapters = []
          const chapterObj = {
            training_program: Number(this.$route.query.programId),
            sort_order: index,
            name: chapter.name,
            visibility: chapter.visibility ? chapter.visibility : false
          }
          if (chapter.id) {
            chapterObj.id = chapter.id
            chapters.push(chapterObj)
            sendRequest(false, false, this, 'api/v1/training/chapters/', 'patch', {chapters}, true,
              () => {
                this.chaptersAdded[index].addChapterName = false
                this.loadChapters()
              }
            )
          } else {
            sendRequest(false, false, this, 'api/v1/training/chapters/', 'post', chapterObj, true,
              () => {
                this.chaptersAdded[index].addChapterName = false
                this.loadChapters()
              }
            )
          }
        }
      })
    },
    updateOnDrag () {
      const chapters = []
      this.chaptersAdded.forEach((item, index) => {
        chapters.push({
          id: item.id,
          training_program: Number(this.$route.query.programId),
          sort_order: index,
          name: item.name,
          visibility: item.visibility
        })
      })
      sendRequest(false, false, this, 'api/v1/training/chapters/', 'patch', {chapters}, true,
        () => {
          this.loadChapters()
        }
      )
    }
  },
  created () {
    if (this.$route.query.programId) this.loadChapters()
  }
}
</script>

<style lang="scss">
.drag-indecator {
  color: #CDCDCD;
  transform: rotate(90deg);
  cursor: grab;
}
</style>