<template>
  <div>
    <h2 class="font-medium">{{ program.name ? program.name : $t('Training Program') }}</h2>
    <div class="flex my-7 sm:justify-start justify-center">
      <div v-for="tab in tabs" :key="tab.index" @click="changeTabFilter(tab.value)" :class="`${$route.query.tab === tab.value ? 'active-tab' : 'text-darkgray border-0 border-b border-solid border-grey'} py-3 px-8 cursor-pointer`">
        <p class="font-medium text-lg leading-tight">{{$t(tab.name)}}</p>
      </div>
    </div>
    <details-training v-if="activeTab === 'details'" @program="program = $event"></details-training>
    <content-training v-else-if="activeTab === 'content'"></content-training>
    <insights-training v-else-if="activeTab === 'insights'"></insights-training>
  </div>
</template>
<script>
import ContentTraining from './components/Content.vue'
import DetailsTraining from './components/Details.vue'
import InsightsTraining from './components/Insights.vue'

export default {
  data () {
    return {
      tabs: [
        { 
          name: 'Details',
          value:'details'
        },
        {
          name: 'Content',
          value: 'content'
        },
        {
          name: 'Insights',
          value: 'insights'
        }
      ],
      activeTab: '',
      programId: this.$route.query.programId,
      trainingProgramDetails: {},
      program: {}
    }
  },
  watch: {
    '$route.params.lang' () {
      this.$router.push({
        query: {
          tab: this.activeTab,
          programId: this.programId
        }
      }).catch(() => {})
    },
    '$route.query.tab' (val) {
      this.activeTab = val
    },
    activeTab (val) {
      this.$router.push({
        query: {
          tab: val,
          programId: this.$route.query.programId
        }
      }).catch(() => {})
    }
  },
  components: {
    DetailsTraining,
    ContentTraining,
    InsightsTraining
  },
  methods: {
    changeTabFilter (tab) {
      this.activeTab = tab
      this.$router.push({
        query: {
          tab,
          programId: this.$route.query.programId ? this.$route.query.programId : this.programId
        }
      }).catch(() => {})
    }
  },
  created () {
    this.changeTabFilter(this.$route.query.tab ? this.$route.query.tab : this.tabs[0].value)
  }
}
</script>

<style lang="scss">
.active-tab {
  background: #EDF4FB;
  color: #1C5BFE;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 2px solid #1C5BFE !important;
}
.input-order {
  border: solid 1px #CDCDCD;
  padding: 8px;
  border-radius: 4px;
  background-color: #FFF;
  color: #1C5BFE;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  &::placeholder {
    color: #9DADC2 !important;
  }
}
</style>